@import './assets/scss/variables';
@import './assets/scss/reset';

@font-face {
  font-family: Sohne;
  font-style: normal;
  font-weight: 400;
  src: url("/src/client/assets/fonts/Sohne-Extra.otf") format("woff");
}

@font-face {
	font-family: 'ecoico';
	src:url('/src/client/assets/fonts/ecoico.eot');
	src:url('/src/client/assets/fonts/ecoico.eot?#iefix') format('embedded-opentype'),
		url('/src/client/assets/fonts/ecoico.woff') format('woff'),
		url('/src/client/assets/fonts/ecoico.ttf') format('truetype'),
		url('/src/client/assets/fonts/ecoico.svg#ecoico') format('svg');
	font-weight: normal;
	font-style: normal;
} /* Made with http://icomoon.io/ */

* {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  outline: none;
}

input[type="submit"]::-moz-focus-inner {
  border: none;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 13pt;
  width: 100%;
  position: relative;
  background-color: white;
  height: 100% !important;
  color: #495057;
  background: url('/src/client/assets/img/lines-2.png');
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  letter-spacing: -0.5px;
  width: 100%;
  font-size: 13pt;
  // transition: all 0.1s ease-in-out;
}

.body {
  position: relative;
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  position: relative;
  flex: 1;
}

a {
  text-decoration: underline;
  cursor: pointer;
  color: $link !important;

  &:hover {
    text-decoration: underline;
    color: $link-hover !important;
  }
}

h1 {
  font-family: Sohne, 'Helvetica', sans-serif;
  margin: 10px 0;
  color: black;
}

h2,
h3 {
  font-family: 'Helvetica', sans-serif;
  margin: 10px 0;
  color: black;
}

h1 {
  font-size: 2.4em;
  letter-spacing: -1px;
  line-height: 1.2;
  margin: 20px 0;
  text-transform: uppercase;
}

h2 {
  font-size: 2em;
  margin: 20px 0;
  line-height: 1.2;
  text-decoration: none;
}

h3 {
  font-size: 1.7em;
}

h4 {
  font-weight: bold;
  font-size: 1.4em;
}

/* --- General --- */

.hidden {
  display: none !important;
}

button {
  cursor: pointer;
}

.mainBody {
  display: block;
  position: relative;
  max-width: $max-width-section;
  margin: 0 auto;
  padding-top: $headerHeight;
}

.mainBodyFull {
  width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
}

.mainBodyError {
  max-width: 100%;
  width: 100%;
}

input[type=text],
textarea {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 50px;
  box-shadow: $background-input-shadow;
  padding: 13px 25px;
  margin: 0 10px;
  font-size: 1.1em;
}

input[type=text]:focus,
textarea:focus {
  box-shadow: 0 0 10px rgb(25, 147, 247);
  border: 1px solid rgba(25, 147, 247, 1);
}

hr {
  margin: 10px 0;
}

select {
  padding: 10px;
  cursor: pointer;
  min-width: 250px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" fill="black" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>') right center no-repeat;
  background-size: 30px;
  background-color: white;
}

p {
  margin: 5px 0;
  padding: 5px 0;
}

.pagination {
  position: relative;
  padding: 10px;
  list-style: none;
  border-radius: 3px;

  li {
    display: inline-block;

    a,
    span {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
    }

    &.active {
      position: relative;

      a {
        z-index: 3;
        display: block;
        color: #fff;
        border-radius: 3px;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 800px) {
  h1 {
    font-size: 2em;
  }
}
